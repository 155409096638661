@font-face {
  font-family: 'dashicons';
  src:  url('../fonts/dashicons.eot?t9owg8');
  src:  url('../fonts/dashicons.eot?t9owg8#iefix') format('embedded-opentype'),
    url('../fonts/dashicons.ttf?t9owg8') format('truetype'),
    url('../fonts/dashicons.woff?t9owg8') format('woff'),
    url('../fonts/dashicons.svg?t9owg8#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'iconpack';
  src:  url('../fonts/iconpack.eot?t9owg8');
  src:  url('../fonts/iconpack.eot?t9owg8#iefix') format('embedded-opentype'),
    url('../fonts/iconpack.ttf?t9owg8') format('truetype'),
    url('../fonts/iconpack.woff?t9owg8') format('woff'),
    url('../fonts/iconpack.svg?t9owg8#iconpack') format('svg');
  font-weight: normal;
  font-style: normal;
}

i.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'iconpack' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.icon-arrow3:before {
    content: "\e912";
  }
  &.icon-envelope:before {
    content: "\e915";
  }
  &.icon-play:before {
    content: "\e900";
  }
  &.icon-arrow2:before {
    content: "\e901";
  }
  &.icon-contact:before {
    content: "\e902";
  }
  &.icon-download:before {
    content: "\e903";
  }
  &.icon-ok:before {
    content: "\e904";
  }
  &.icon-ok2:before {
    content: "\e905";
  }
  &.icon-star:before {
    content: "\e906";
  }
  &.icon-close-circle:before {
    content: "\e907";
  }
  &.icon-facebook:before {
    content: "\e908";
  }
  &.icon-icon-material:before {
    content: "\e909";
  }
  &.icon-circle-arrow:before {
    content: "\e90e";
  }
  &.icon-instagram:before {
    content: "\e90f";
  }
  &.icon-circle-arrow3:before {
    content: "\e910";
  }
  &.icon-build:before {
    content: "\e90a";
  }
  &.icon-calculate:before {
    content: "\e90b";
  }
  &.icon-studies:before {
    content: "\e90c";
  }
  &.icon-draft:before {
    content: "\e90d";
  }
  &.icon-close:before {
    content: "\e911";
  }
  &.icon-menu:before {
    content: "\e913";
  }
  &.icon-youtube:before {
    content: "\e914";
  }

}
